"use client";
import { useEffect } from "react";

import { useStore } from "../../service/mobx";

export default function HooksDiscover() {
  const { analytics } = useStore();

  useEffect(() => {
    // set context
    analytics.set.context("Discover", "View");
    // remove context when component unmounts
    return analytics.set.context;
  }, [analytics]);
}
